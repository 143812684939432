export function Background() {

  return (
    <div className="absolute size-full overflow-x-hidden"
    // style={{backgroundImage: 'radial-gradient(at 83% 19%, rgb(216, 180, 254) 0, transparent 71%), radial-gradient(at 3% 78%, rgb(103, 232, 249) 0, transparent 48%), radial-gradient(at 83% 78%, rgb(253, 164, 175) 0, transparent 49%)'}}
    >
      <div
        className="Red absolute left-1/2 top-1/2 h-1/2 w-3/4 origin-top-left rounded-full bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#FF8181]/50 to-white/0 blur-3xl"></div>
      <div
        className="Purple absolute left-1/2 h-1/2 w-3/4 origin-top-left rounded-full bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#AF89FF]/50 to-white/0 blur-3xl"></div>
      <div
        className="Blue absolute right-1/2 top-1/2 h-1/2 w-3/4 origin-top-left rounded-full bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#81F0FF]/50 to-white/0 blur-3xl"></div>

    </div>
  )
}
