"use client";

import FooterSignIn from "./footer-sign-in";
import SignInWithEmail from "./sign-in-email";
import { Background } from "@/components/background";
import { Icons } from "@/components/icons";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import UserAgentUtils from "@/lib/utils/user-agent-utils";
import { signIn } from "next-auth/react";
import { useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";
import {
  FeatureFlag,
  featureFlagEnabled,
} from "../../../../lib/config/featureFlagEnabled";
import Link from "next/link";
import { Logo } from "../../../../components/logo/logo";
import { useQueryState } from "nuqs";
import useLoginModalStore from "../../../../hooks/auth/use-login-modal-store";

export const dynamic = "force-dynamic";

export interface SignInComponentProps {
  callBackUrl: string;
}
const SignInComponent: React.FC<SignInComponentProps> = ({ callBackUrl }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [userAgent, setUserAgent] = React.useState("");
  const loginModal = useLoginModalStore();
  const [callBackValid, setCallBackValid] = useState(callBackUrl);
  const [showVerificationStep, setShowVerificationStep] = useState(true);
  const [d, setD] = useQueryState("d");
  const [a, setA] = useQueryState("a");

  useEffect(() => {
    if (d && a) {
      setIsActive((prev) => !prev);
      setCallBackValid(`${callBackUrl}?d=${d}&a=${a}`);
    }
  }, []);

  React.useEffect(() => {
    if (window) {
      setUserAgent(window.navigator.userAgent);
    }
  }, []);

  const handleSetActive = () => {
    setIsActive(!isActive);
  };
  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="fixed left-0 top-0 -z-10 size-full bg-slate-50">
        <Background />
      </div>
      <div className="flex size-full max-w-3xl flex-1 flex-col">
        <main className="relative flex h-screen w-full flex-col p-4">
          {isActive ? (
            <SignInWithEmail
              handleSetActive={handleSetActive}
              callBackUrl={callBackValid}
              showVerificationStep={showVerificationStep}
              setShowVerificationStep={setShowVerificationStep}
            />
          ) : (
            <>
              <div className="mb-[68px]">
                <Icons.close
                  className="size-6"
                  onClick={() => {
                    loginModal.onClose();
                  }}
                />
              </div>
              <div className="mb-11 flex w-full flex-col justify-center gap-1">
                <div>
                  <Logo width={[62, 29]} height={22} />
                </div>
                <div className="text-center text-xl font-semibold text-secondary-100">
                  Đăng nhập hoặc đăng ký
                </div>
                <div className="text-center text-sm font-normal text-[#61677C]">
                  Để lưu lại thông tin và khám phá ở bất cứ đâu trên Laka.
                </div>
              </div>
            </>
          )}

          <div className={`flex w-full flex-col gap-4`}>
            {(isActive && !UserAgentUtils.isWebView(userAgent)) && (
              <div className="flex w-full items-center gap-4 px-4">
                <div className="h-px flex-1 bg-line"></div>
                <div className="text-xs font-normal text-[#61677C]">Hoặc</div>
                <div className="h-px flex-1 bg-line"></div>
              </div>
            )}

            {featureFlagEnabled(FeatureFlag.FacebookLogin) && (
              <button
                onClick={() => {
                  signIn("facebook", {
                    callbackUrl: callBackUrl,
                  });
                }}
                className={`${cn(
                  buttonVariants({ variant: "active", size: "xl" })
                )} w-full !justify-start px-6`}
              >
                <Icons.facebook className="mr-4 size-6" />
                Tiếp tục bằng Facebook
              </button>
            )}

            {!UserAgentUtils.isWebView(userAgent) && (
              <button
                onClick={() => {
                  signIn("google", {
                    callbackUrl: callBackUrl,
                  });
                }}
                className={`${cn(
                  buttonVariants({ variant: "default", size: "xl" })
                )} w-full !justify-start !rounded-2xl border border-line bg-white px-6 !text-secondary-100 hover:!text-white`}
              >
                <Icons.googleV2 className="mr-4 size-6" />
                <span>Tiếp tục bằng Google</span>
              </button>
            )}

            {!isActive && (
              <button
                onClick={() => setIsActive(true)}
                className={`${cn(
                  buttonVariants({ variant: "default", size: "xl" })
                )} w-full !justify-start !rounded-2xl px-6`}
              >
                <Icons.mail stroke="#FFF" className="mr-4 size-6" />
                Tiếp tục với Email
              </button>
            )}
          </div>

          <FooterSignIn />
        </main>
      </div>
    </div>
  );
};

export default SignInComponent;
