import Link from "next/link"
import React from "react"

const FooterSignIn = () => {
  return (
    <div className="fixed inset-x-0 bottom-8 w-full px-4 text-center text-xs font-normal text-secondary-100">
      Bằng cách đăng ký hoặc đăng nhập, bạn đã hiểu và đồng ý với{" "}
      <Link
        href="/policies#dieu-khoan-su-dung"
        target="_blank"
        className="text-action"
      >
        Điều khoản sử dụng {""}
      </Link>
      và{" "}
      <Link
        href="/policies#chinh-sach-bao-mat-thong-tin-khach-hang"
        target="_blank"
        className="text-action"
      >
        Chính sách bảo mật{" "}
      </Link>
      của Laka
    </div>
  )
}

export default FooterSignIn
